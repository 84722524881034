<template>
  <div class="mobile-container">
    <mobileStatusBar mode="gray" title="工单" />
    <van-list v-model="loading" :finished="finish" @load="handleGetGdList" loading-text="加载中..." finished-text="" error-text="加载失败">
      <div v-if="finish && !loading && gdList.length == 0" class="empty-item">
        <div class="icon img-cover"><img src="../../assets/images/empty-icon.png" /></div>
      </div>
      <div v-else class="gd-list">
        <router-link :to="{path: '/gdDetail', query: {id: item.Id}}" class="gd-item" v-for="(item, index) in gdList" :key="index">
          <div class="header-item">
            <div class="class">{{ item.CnClassName }}</div>
            <div class="status" :class="item.Status == 1 ? 'status1' : ''">{{ item.Status == 1 ? '已完成' : '待处理' }}</div>
          </div>
          <div class="time">{{ $TOOL.dateFormat(item.AddTime * 1000) }}</div>
          <div class="desc">{{ item.Desc }}</div>
        </router-link>
      </div>
    </van-list>
    <div class="btn-item">
      <router-link :to="{path: '/addGd'}" class="add-btn public-btn">添加工单</router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '../../components/mobileStatusBar.vue'

export default {
  name: "Gd",
  components: {
    mobileStatusBar
  },
  data() {
    return {
      PageCount: 10,
      Page: 1,
      loading: false,
      finish: false,
      gdList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  watch: {
    
  },
  mounted() {

  },
  methods: {
    async handleGetGdList() {
      const { PageCount, Page, gdList } = this
      const params = {
        PageCount,
        Page
      }
      var res = await this.$API.ticketsList.post(params)
      this.gdList = [
        ...gdList,
        ...res.info
      ]
      this.Page = Page + 1
      this.finish = res.info.length < PageCount
      this.loading = false
    }
  },
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f8f8;
}
.gd-list {
  padding: .24rem .32rem;
  .gd-item {
    display: block;
    margin-bottom: .24rem;
    padding: .24rem;
    background-color: #fff;
    border-radius: .16rem;
    color: #242941;
    .header-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: .4rem;
      font-size: .28rem;
      font-weight: bold;
      .class {
        flex: 1;
      }
      .status {
        color: #ff2442;
        &.status1 {
          color: #1399ff;
        }
      }
    }
    .time {
      margin-top: .2rem;
      color: #ccc;
      font-size: .24rem;
      line-height: .34rem;
    }
    .desc {
      margin-top: .24rem;
      font-size: .28rem;
      line-height: .4rem;
    }
  }
}
.btn-item {
  .add-btn {
    position: fixed;
    bottom: 1.6rem;
    left: 50%;
    margin-left: -3.43rem;
    width: 6.86rem;
    height: .88rem;
    border-radius: .12rem;
    color: #fff;
    font-size: .3rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>