<template>
  <div class="mobile-status-bar-container">
    <div class="content" :class="mode" :style="bgColor ? 'background-color: '+ bgColor +';' : ''">
      <div class="left-btn img-cover" @click="$router.go(-1)">
        <img v-if="mode == 'dart'" src="../assets/images/back-icon-white.png" />
        <img v-else src="../assets/images/back-icon-black.png" />
      </div>
      <div class="title-item">{{ title }}</div>
      <div v-if="shareData && shareData.show" class="share-item" @click="shareData.callback">
        <div class="icon img-cover">
          <img src="../assets/images/share-icon.png" />
        </div>
        <div class="name">分享立赚</div>
      </div>
      <div v-if="rightData && rightData.show" class="right-item" @click="rightData.callback">{{ rightData.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileStatusBar',
  props: {
    title: String,
    mode: {
      type: String,
      default: 'light' //gray, light, dart
    },
    shareData: Object,
    rightData: Object,
    bgColor: String
  }
}
</script>

<style scoped lang="less">
.mobile-status-bar-container {
  width: 100%;
  height: .88rem;
  .content {
    height: .88rem;
    position: fixed;
    top: 0;
    z-index: 10;
    width: var(--max-width);
    left: 50%;
    margin-left: var(---max-width-50);
    &.gray {
      background-color: #f7f7f7;
      color: #030304;
    }
    &.light {
      background-color: #fff;
      color: #030304;
    }
    &.dart {
      color: #fff;
    }
    .title-item {
      text-align: center;
      line-height: .88rem;
      font-size: .32rem;
      font-weight: 500;
    }
    .left-btn {
      cursor: pointer;
      position: absolute;
      left: .14rem;
      top: .14rem;
      width: .6rem;
      height: .6rem;
    }
    .share-item {
      cursor: pointer;
      position: absolute;
      top: .24rem;
      right: .32rem;
      height: .4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffba68;
      font-size: .26rem;
      font-weight: bold;
      .icon {
        width: .32rem;
        height: .32rem;
        margin-right: .12rem;
      }
    }
    .right-item {
      cursor: pointer;
      position: absolute;
      top: .24rem;
      right: .32rem;
      height: .4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #1399ff;
      font-size: .26rem;
      font-weight: bold;
    }
  }
}
</style>
